import Quill from "quill"
const BlockEmbed = Quill.import('blots/block/embed')
class Hr extends BlockEmbed {
  static handler() {
    let range = this.quill.getSelection(true);
    this.quill.insertText(range.index, '\n', Quill.sources.USER);
    this.quill.insertEmbed(range.index + 1, 'divider', false, Quill.sources.USER);
    this.quill.setSelection(range.index + 2, Quill.sources.SILENT);
  }
}
Hr.blotName = 'divider'
Hr.className = 'custom-hr'
Hr.tagName = 'hr'
export default Hr
