import { Controller } from "stimulus"
import ImageResize from 'quill-image-resize';
import Quill from "quill"
import { htmlEditButton } from "utilities/quill/htmlEditButton";
import Delta from "quill-delta"
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);

export default class extends Controller {
  static targets = ['editor']
  connect() {
    this.editorTarget.innerHTML = this.getContents()
    let editor = new Quill(this.editorTarget,{
      theme: 'snow',
      modules: {
        htmlEditButton: { msg: '' },
        imageResize: {
          displaySize: true
        },
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          ['link', 'image'],

          ['clean']
        ]
      }
    });
    // Store accumulated changes
    var change = new Delta();
    editor.on('text-change', function(delta) {
      change = change.compose(delta);
    });

    // Save periodically
    setInterval(function() {
      if (change.length() > 0) {
        console.log('Saving changes', change);
        // localStorage.contents = JSON.stringify(editor.getContents());
        localStorage.contents = editor.root.innerHTML;
        change = new Delta();
      }
    }, 5*1000);
  }

  getContents() {
    return localStorage.contents || '<h3>Este es un ejemplo de lo que se puede hacer:</h3><div style="width: 100%;"><div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;"><iframe frameborder="0" width="1200px" height="675px" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://view.genial.ly/5dde51f5338e750ef4beb29d" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe> </div> </div>'
  }
}
