import Flatpickr from 'stimulus-flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es.js'

import 'flatpickr/dist/flatpickr.css'

export default class extends Flatpickr {
  initialize() {
    this.config = {
      locale: Spanish,
      enableTime: true,
      time_24hr: true,
      dateFormat: 'Y-m-d H:i'
    }
  }
}