window.Init = (function() {
  var controllers = {
    pages: {
      new: PagesController.init,
      edit: PagesController.init
    },
    publications: {
      new: PublicationsController.init,
      edit: PublicationsController.init
    },
    site_configurations: {
      edit: SiteConfigController.init
    }
  };

  return (controller, action) => {
    if("undefined" !== typeof(controllers[controller]))
      if("undefined" !== typeof(controllers[controller][action]))
          controllers[controller][action]();
  };
})();
