import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = [
    'selectize_material', 'selectize_city', 'selectize_state',
    'selectize_location_type'
  ]

  manageLocation() {
    var location_val = document.querySelector('select[name="product[location_type]"]').value
    if (location_val == 'State') {
      document.querySelector('[id="state_select"]').style.display = 'block';
      document.querySelector('[id="city_select"]').style.display = 'none';
    } else if (location_val == 'City') {
      document.querySelector('[id="state_select"]').style.display = 'none';
      document.querySelector('[id="city_select"]').style.display = 'block';
    }
  }

  connect() {

    const location_typeChoices = new Choices(this.selectize_location_typeTarget,
      {itemSelectText: ''}
    );
    const stateChoices = new Choices(this.selectize_stateTarget,
      {itemSelectText: ''}
    );
    const cityChoices = new RemoteChoices(this.selectize_cityTarget,
      {itemSelectText: ''},
      { url: 'v1/cities' }
    )

    const materialChoices = new RemoteChoices(this.selectize_materialTarget,
      {itemSelectText: ''},
      { url: 'v1/materials' }
    )
  } 
}
