import Choices from 'choices.js'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "choices" ]

  connect() {
    const choices = new Choices(this.choicesTarget, {
      removeItemButton: false
    });
  }
}
