import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['filter_button']

  connect() {
  }

  filter_users(){
    this.filter_buttonTarget.click()
  }
}
