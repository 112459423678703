import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'
import Quill from "quill"
export default class extends Controller {
  static targets = ['selectize_batch', 'selectize_unit_measure', 'editor', 'content']

  connect() {
    if (this.hasSelectize_unit_measureTarget) {
      const unitMeasuresChoices = new Choices(this.selectize_unit_measureTarget,
        { itemSelectText: '' }
      );
    }
    if (this.hasEditorTarget) {
      this.textEditor()
    }
  }

  textEditor() {
    this.editorTarget.innerHTML = this.contentTarget.value
    var toolbarOptions = ['bold', 'italic', 'link'];
    let editor = new Quill(this.editorTarget, {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions
      }
    })
    this.editorTarget.editor = editor
  }
  prepareParams(e) {
    let regex = /(<([^>]+)>)/ig
    let text = this.editorTarget.editor.root.innerHTML
    let hasText = !!text.replace(regex, "").length;
    this.contentTarget.value = hasText ? text : ''
  }
}
