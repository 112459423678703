import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = ['selectize_weather', 'selectize_state', 'selectize_municipalities']

  connect() {
    const weatherChoices = new Choices(this.selectize_weatherTarget,
      { itemSelectText: '' }
    );
    
    const stateChoices = new Choices(this.selectize_stateTarget,
      { itemSelectText: '' }
    );
    this._municipalitiesChoices = new Choices(this.selectize_municipalitiesTarget,
      {
        itemSelectText: '',
        duplicateItemsAllowed: false,
        callbackOnInit: function() {
          let choices_by_states = this.config.choices.reduce((r, a) => {
            r[a.customProperties] = [...r[a.customProperties] || [], a];
            return r;
          }, {});
          this.config.choices_by_state = choices_by_states
          this.clearChoices()
          var val =  stateChoices.getValue().value
          this.setChoices(this.config.choices_by_state[val])
        }
      }
    );
    this.selectize_stateTarget.addEventListener(
      'change',
      function(event) {
        this._municipalitiesChoices.clearStore()
        this._municipalitiesChoices.setChoices(
          this._municipalitiesChoices.config.choices_by_state[event.detail.value]
        )
      }.bind(this),
      false,
    );
  }
}
