import { Controller } from 'stimulus'
import Choices from 'choices.js'
import Api from '../../api/base.js'

export default class extends Controller {
  static targets = [
    'selectize_city', 'selectize_state', 'selectedCity'
  ]
  connect() {
    this.initGeoSelectors()
  }

  initGeoSelectors() {
    const _this = this
    const selectizeState = new Choices(this.selectize_stateTarget,
      { itemSelectText: '', position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados' }
    )
    const selectizeCity = new Choices(this.selectize_cityTarget,
      { itemSelectText: '', position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados' }
    )

    this.selectize_stateTarget.addEventListener(
      'change',
      function (event) {
        _this.getCities(selectizeCity, event)
      },
      false
    )

    if (this.selectize_stateTarget.value) {
      this.getCities(selectizeCity, { detail: { value: this.selectize_stateTarget.value } }, false)
    }
  }

  getCities(selectizeCity, event, stateEvent = true) {
    selectizeCity.clearChoices()
    var api = new Api('v1/city_catalogs/state_cities')
    api.all(function (results) {
      results = results.map(x => ({ value: x.id, label: x.name }))
      results.unshift(({ value: '', label: '' }))
      selectizeCity.setChoices(
        results,
        'value',
        'label',
        false,
      );
      selectizeCity.setChoiceByValue(stateEvent ? '' : parseInt(this.selectedCityTarget.value))
    }.bind(this),
      function (result, error) { console.log(error) },
      { state_id: event.detail.value }
    )
  }
}
