import { Controller } from "stimulus"
import Choices from 'choices.js'
import Api from '../../api/base'

export default class extends Controller {
  static targets = ['selectize_material']

  updateUnitMeasure() {
    var material_id = document.querySelector('[id="consumption_rate_material_id"]').value
    document.querySelector('[id="unit_measure_tag"]').style.display = 'block';
    var api = new Api('v1/materials/unit_measure')
    api.all(function(results) {
      document.querySelector('[id="unit_measure_tag"]').innerHTML = results.abbreviation
    }.bind(this),
      function(result, error) {console.log(error) },
      { query: material_id }
    )
  }

  connect() {
    const searchMaterialChoices = new Choices(this.selectize_materialTarget,
      {itemSelectText: ''}
    );
  }
}
