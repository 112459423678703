import { Controller } from 'stimulus'
import Choices from 'choices.js'
import Api from '../../api/base.js'

export default class extends Controller {
  static targets = ['selectize_state']
  connect() {
    this.initGeoSelectors()
  }

  initGeoSelectors() {
    const _this = this
    const selectizeState = new Choices(this.selectize_stateTarget,
      { itemSelectText: '', position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados' }
    )
  }
}
