import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['selectize_material_family']

  connect() {
    const searchMaterialFamilyChoices = new Choices(this.selectize_material_familyTarget,
      {itemSelectText: ''}
    );
  }
}
