import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = ['selectize_material_family', 'selectize_unit_measure', 'selectize_category']

  connect() {
    const categoryChoices = new Choices(this.selectize_categoryTarget,
      {itemSelectText: ''}
    );

    const materialFamilyChoices = new Choices(this.selectize_material_familyTarget,
      {itemSelectText: ''}
    );

    const unitMeasuresChoices = new Choices(this.selectize_unit_measureTarget,
      {itemSelectText: ''}
    );
  }
}
