import { Controller } from "stimulus"
import ImageResize from 'quill-image-resize';
import Quill from "quill"
import { htmlEditButton } from "utilities/quill/htmlEditButton";
import Delta from "quill-delta"
import Hr from "utilities/quill/hr"
import Choices from 'choices.js'
import axios  from 'axios';
import "quill-mention"
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register("formats/hr", Hr);

async function suggestConcepts(searchTerm) {
  try {
    const response = await axios.get('/api/v1/concepts/concepts_mention');
    return response.data.filter(concept => concept.value
                                                  .toLowerCase()
                                                  .normalize('NFD')
                                                  .replace(/[\u0300-\u036f]/g, "")
                                                  .includes(searchTerm.toLowerCase()));
  } catch (error) {
    console.error(error);
  }
}

export default class extends Controller {
  static targets = [
    'selectize_question_type', 'selectize_theme', 'editor'
  ]
  prepareParams(e) {
    let content = document.querySelector('[name="frequent_question[answer_text]"]')
    content.value = this.editorTarget.editor.root.innerHTML;
  }

  manageTheme() {
    var question_val = document.querySelector('select[name="frequent_question[question_type]"]').value
    if (question_val == 'construction_questions') {
      document.querySelector('[id="theme_select"]').style.display = 'block';
    } else {
      document.querySelector('[id="theme_select"]').style.display = 'none';
    }
  }

  connect() {

    const question_typeChoices = new Choices(this.selectize_question_typeTarget,
      {itemSelectText: ''}
    );
    const themeChoices = new Choices(this.selectize_themeTarget,
      {itemSelectText: ''}
    );

    var content = document.querySelector('[name="frequent_question[answer_text]"]')
    this.editorTarget.innerHTML = content.value
    let editor = new Quill(this.editorTarget, {
      theme: 'snow',
      modules: {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          showDenotationChar: false,
          mentionDenotationChars: ["#"],
          source: async function(searchTerm, renderList) {
            const matchedPeople = await suggestConcepts(searchTerm);
            renderList(matchedPeople);
          }
        },
        htmlEditButton: { msg: '' },
        imageResize: {
          displaySize: true
        },
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['blockquote', 'code-block'],
          ['link', 'image'],

          ['clean']
        ]
      }
    });
    this.editorTarget.editor = editor
  }
}
