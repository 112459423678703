// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require('quill')
require("@rails/actiontext")
require("micromodal/dist/micromodal")
require([
  "codemirror", "codemirror/mode/htmlmixed/htmlmixed"
]);
require("controllers")
require("init")
import "controllers"
import CodeMirror from "codemirror"
import "codemirror/mode/htmlmixed/htmlmixed"
import Trix from "trix"
import "@kollegorna/cocoon-vanilla-js"
window.Trix = Trix
window.CodeMirror = CodeMirror

require("extensions")

window.addEventListener("turbolinks:load", () => {
  Init(
    document.body.attributes.controller_name.value,
    document.body.attributes.action_name.value
  )

  // Change URL button for download visitants subscribe to interest list
  let check_list = document.getElementById('check_subscribe')
  let check_verified = document.getElementById('verified_users')
  let flag = false;
  let btnDownload = document.getElementById('btnDownload')
  let url_base = btnDownload.href
  let url = url_base

  btnDownload.addEventListener('click', (e) => {
    let initial_date = document.getElementById('initial_date').value
    let final_date = document.getElementById('final_date').value
    let verified_users = check_verified.checked
    let list_subscribe = check_list.checked
    url += `?initial_date=${initial_date}&final_date=${final_date}` +
           `&list_subscribe=${list_subscribe}&verified_users=${verified_users}`

    location.href=url
  })
});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
