import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = [
    'selectize_actuates_on_type', 'selectize_activity', 'selectize_question',
    'selectize_option'
  ]

  manageActuatesOn() {
    var actuates_on_val = document.querySelector('select[name="actuator[actuates_on_type]"]').value
    if (actuates_on_val == 'Activity') {
      document.querySelector('[id="activity_select"]').style.display = 'block';
      document.querySelector('[id="question_select"]').style.display = 'none';
      document.querySelector('[id="option_select"]').style.display = 'none';
    } else if (actuates_on_val == 'Question') {
      document.querySelector('[id="activity_select"]').style.display = 'none';
      document.querySelector('[id="question_select"]').style.display = 'block';
      document.querySelector('[id="option_select"]').style.display = 'none';
    } else if (actuates_on_val == 'QuestionOption'){
      document.querySelector('[id="activity_select"]').style.display = 'none';
      document.querySelector('[id="question_select"]').style.display = 'none';
      document.querySelector('[id="option_select"]').style.display = 'block';
    }
  }

  connect() {

    const actuates_on_typeChoices = new Choices(this.selectize_actuates_on_typeTarget,
      {itemSelectText: ''}
    );
    const activityChoices = new Choices(this.selectize_activityTarget,
      {itemSelectText: ''}
    );
    const questionChoices = new Choices(this.selectize_questionTarget,
      {itemSelectText: ''}
    )
    const optionChoices = new Choices(this.selectize_optionTarget,
      {itemSelectText: ''}
    )
  } 
}
