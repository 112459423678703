import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = [
    'actuates_on_id', 'value_source_id', 'selectize_actuates_on_type',
    'selectize_value_source_type'
  ]

  manageActuatesOn() {
    var actuates_on_val = document.querySelector('select[name="actuator[actuates_on_type]"]').value
    if (actuates_on_val == 'Activity') {
      document.querySelectorAll('.aoi-activity').forEach(el => { el.style.display = 'block'; })
      document.querySelectorAll('.aoi-question').forEach(el => { el.style.display = 'none'; })
      document.querySelectorAll('.aoi-question-option').forEach(el => { el.style.display = 'none'; })
    } else if (actuates_on_val == 'Question') {
      document.querySelectorAll('.aoi-activity').forEach(el => { el.style.display = 'none'; })
      document.querySelectorAll('.aoi-question').forEach(el => { el.style.display = 'block'; })
      document.querySelectorAll('.aoi-question-option').forEach(el => { el.style.display = 'none'; })
    } else if (actuates_on_val == 'QuestionOption'){
      document.querySelectorAll('.aoi-activity').forEach(el => { el.style.display = 'none'; })
      document.querySelectorAll('.aoi-question').forEach(el => { el.style.display = 'none'; })
      document.querySelectorAll('.aoi-question-option').forEach(el => { el.style.display = 'block'; })
    }
    this.actuates_on_idTarget.value = ''
  }

  manageValueSource() {
    var value_source_val = document.querySelector('select[name="actuator[value_source_type]"]').value
    if (value_source_val == 'Activity') {
      document.querySelectorAll('.vsi-activity').forEach(el => { el.style.display = 'block'; })
      document.querySelectorAll('.vsi-question').forEach(el => { el.style.display = 'none'; })
      document.querySelectorAll('.vsi-question-option').forEach(el => { el.style.display = 'none'; })
    } else if (value_source_val == 'Question') {
      document.querySelectorAll('.vsi-activity').forEach(el => { el.style.display = 'none'; })
      document.querySelectorAll('.vsi-question').forEach(el => { el.style.display = 'block'; })
      document.querySelectorAll('.vsi-question-option').forEach(el => { el.style.display = 'none'; })
    } else if (value_source_val == 'QuestionOption'){
      document.querySelectorAll('.vsi-activity').forEach(el => { el.style.display = 'none'; })
      document.querySelectorAll('.vsi-question').forEach(el => { el.style.display = 'none'; })
      document.querySelectorAll('.vsi-question-option').forEach(el => { el.style.display = 'block'; })
    }
    this.value_source_idTarget.value = ''
  }

  connect() {
    // const actuates_on_idChoices = new Choices(
    //   this.actuates_on_idTarget, { itemSelectText: '' }
    // );

    // const actuates_on_typeChoices = new Choices(
    //   this.selectize_actuates_on_typeTarget, { itemSelectText: '' }
    // );

    // const value_source_typeChoices = new Choices(
    //   this.selectize_value_source_typeTarget, { itemSelectText: '' }
    // );
  } 
}
