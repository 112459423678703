import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = ['selectize_stage']

  connect() {
    const stageChoices = new Choices(this.selectize_stageTarget,
      {itemSelectText: ''}
    );
  }
}
