SiteConfigController = (function(){
  var _init = function() {
    window.stylesheets_editor = CodeMirror.fromTextArea(
      document.getElementById("site_configuration_stylesheets"), {
        lineNumbers: true,
        mode: "htmlmixed"
      }
    );
    window.javascripts_editor = CodeMirror.fromTextArea(
      document.getElementById("site_configuration_javascripts"), {
        lineNumbers: true,
        mode: "htmlmixed"
      }
    );
    window.footer_editor = CodeMirror.fromTextArea(
      document.getElementById("site_configuration_footer"), {
        lineNumbers: true,
        mode: "htmlmixed"
      }
    );
    window.menu_editor = CodeMirror.fromTextArea(
      document.getElementById("site_configuration_menu"), {
        lineNumbers: true,
        mode: "htmlmixed"
      }
    );
    window.navbar_editor = CodeMirror.fromTextArea(
      document.getElementById("site_configuration_navbar"), {
        lineNumbers: true,
        mode: "htmlmixed"
      }
    );
    // var editable = document.getElementById('pageEditor');
    // editable.addEventListener('input', function(e) {
    //   window.code_editor.setValue(this.innerHTML);
    // });
    // window.code_editor.on('blur', function(e) {
    //   editable.innerHTML = e.getValue();
    // });
  }

  return {
    init: _init
  };
})();
