import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['searchKeywords', 'searchResults', 'filterBy']
  static values = {
    solution: String
  }

  filterPublications() {
    let solutionId = this.data.get('solutionValue');
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var headers = new Headers();
    headers.append('X-CSRF-TOKEN', token);
    var requestBody = new FormData();
    requestBody.append("keywords", this.searchKeywordsTarget.value);
    requestBody.append("solution_id", solutionId);
    requestBody.append("filter_by", this.filterByTarget.value);
    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: requestBody
    };
    this.searchResultsTarget.innerHTML = '';
    fetch("/filter_publications", requestOptions)
      .then(response => response.json())
      .then(result => {
        this.createResultsCountDiv(result.length)
        result.forEach(c => this.createSearchResultCard(c))
      })
      .catch(error => console.log('error', error));
  }

  createResultsCountDiv(count){
    let resultsElement = document.createElement("div");
    let resultsContent = `
        <p class="mt0 gray">Resultados: ${count}</p>
    `
    resultsElement.innerHTML = resultsContent
    this.searchResultsTarget.append(resultsElement);
  }

  createSearchResultCard(c){
    let outerContainer = document.createElement("div");
    let card = `
      <div class="bg-white br2 shadow-1 pa3 mv3 flex">
        <div class="w-70">
          <h4 class="mv0 gray">${c.section_name}</h4>
          <h3 class="mv0">${c.title}</h3>
          <p class="mv0">${c.description}</p>
          <p class="mv0"><span class="fw6">Palabras clave: </span>${c.keywords}</p>
        </div>
        <div class="w-30 flex items-center">
          <input type="checkbox" value="${c.id}" name="posts[]" id="post-${c.id}">
          <label for="post-${c.id}"> &emsp; Agregar</label>
        </div>
      </div>`
    outerContainer.innerHTML = card
    this.searchResultsTarget.append(outerContainer);
  }

}
