import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = ['selectize_weather', 'selectize_solution_type']

  connect() {
    this.logoLimit()
    const weatherChoices = new Choices(this.selectize_weatherTarget,
      {itemSelectText: ''}
    );
    
    const solutionTypeChoices = new Choices(this.selectize_solution_typeTarget,
      {itemSelectText: ''}
    );
  }

  logoLimit() {
    const containerSponsors = this.element.querySelector("#sponsors")

    containerSponsors.addEventListener("cocoon:before-insert", function(event) {
      const nestFields = containerSponsors.querySelectorAll('.nested-fields')
      const filteredFields = [...nestFields].filter(item => item.style.display != 'none')
      if(filteredFields.length >= 5){
        alert("Límite de marcas alcanzado. Elimina una marca existente para seleccionar una nueva.")
        event.preventDefault();
      }
    });
  }
}
