import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = [
    'questionaire_button', 'variables_button', 'actuators_button', 'activities_button', 'review_blocks_button',
    'questionaire_block', 'variables_block', 'actuators_block', 'activities_block',
    'blueprints_button', 'blueprints_block', 'review_blocks_block'
  ]

  questionaire() {
    this.questionaire_buttonTarget.classList.add('bg-dart-red')
    this.variables_buttonTarget.classList.remove('bg-dart-red')
    this.actuators_buttonTarget.classList.remove('bg-dart-red')
    this.activities_buttonTarget.classList.remove('bg-dart-red')
    this.blueprints_buttonTarget.classList.remove('bg-date-red')
    this.review_blocks_buttonTarget.classList.remove('bg-dart-red')
    this.questionaire_blockTarget.style.display = 'block';
    this.variables_blockTarget.style.display = 'none';
    this.actuators_blockTarget.style.display = 'none';
    this.activities_blockTarget.style.display = 'none';
    this.blueprints_blockTarget.style.display = 'none';
    this.review_blocks_blockTarget.style.display = 'none';
  }

  variables() {
    this.questionaire_buttonTarget.classList.remove('bg-dart-red')
    this.variables_buttonTarget.classList.add('bg-dart-red')
    this.actuators_buttonTarget.classList.remove('bg-dart-red')
    this.activities_buttonTarget.classList.remove('bg-dart-red')
    this.blueprints_buttonTarget.classList.remove('bg-date-red')
    this.review_blocks_buttonTarget.classList.remove('bg-date-red')
    this.questionaire_blockTarget.style.display = 'none';
    this.variables_blockTarget.style.display = 'block';
    this.actuators_blockTarget.style.display = 'none';
    this.activities_blockTarget.style.display = 'none';
    this.blueprints_blockTarget.style.display = 'none';
    this.review_blocks_blockTarget.style.display = 'none';
  }

  actuators() {
    this.questionaire_buttonTarget.classList.remove('bg-dart-red')
    this.variables_buttonTarget.classList.remove('bg-dart-red')
    this.actuators_buttonTarget.classList.add('bg-dart-red')
    this.activities_buttonTarget.classList.remove('bg-dart-red')
    this.blueprints_buttonTarget.classList.remove('bg-date-red')
    this.review_blocks_buttonTarget.classList.remove('bg-date-red')
    this.questionaire_blockTarget.style.display = 'none';
    this.variables_blockTarget.style.display = 'none';
    this.actuators_blockTarget.style.display = 'block';
    this.activities_blockTarget.style.display = 'none';
    this.blueprints_blockTarget.style.display = 'none';
    this.review_blocks_blockTarget.style.display = 'none';
  }

  activities() {
    this.questionaire_buttonTarget.classList.remove('bg-dart-red')
    this.variables_buttonTarget.classList.remove('bg-dart-red')
    this.actuators_buttonTarget.classList.remove('bg-dart-red')
    this.activities_buttonTarget.classList.add('bg-dart-red')
    this.blueprints_buttonTarget.classList.remove('bg-date-red')
    this.review_blocks_buttonTarget.classList.remove('bg-date-red')
    this.questionaire_blockTarget.style.display = 'none';
    this.variables_blockTarget.style.display = 'none';
    this.actuators_blockTarget.style.display = 'none';
    this.activities_blockTarget.style.display = 'block';
    this.blueprints_blockTarget.style.display = 'none';
    this.review_blocks_blockTarget.style.display = 'none';
  }

  blueprints() {
    this.questionaire_buttonTarget.classList.remove('bg-dart-red')
    this.variables_buttonTarget.classList.remove('bg-dart-red')
    this.actuators_buttonTarget.classList.remove('bg-dart-red')
    this.activities_buttonTarget.classList.remove('bg-dart-red')
    this.blueprints_buttonTarget.classList.add('bg-date-red')
    this.review_blocks_buttonTarget.classList.remove('bg-dart-red')
    this.questionaire_blockTarget.style.display = 'none';
    this.variables_blockTarget.style.display = 'none';
    this.actuators_blockTarget.style.display = 'none';
    this.activities_blockTarget.style.display = 'none';
    this.blueprints_blockTarget.style.display = 'block';
    this.review_blocks_blockTarget.style.display = 'none';
  }

  reviewBlocks(){
    this.questionaire_buttonTarget.classList.remove('bg-dart-red')
    this.variables_buttonTarget.classList.remove('bg-dart-red')
    this.actuators_buttonTarget.classList.remove('bg-dart-red')
    this.activities_buttonTarget.classList.remove('bg-dart-red')
    this.blueprints_buttonTarget.classList.remove('bg-date-red')
    this.review_blocks_buttonTarget.classList.add('bg-date-red')
    this.questionaire_blockTarget.style.display = 'none';
    this.variables_blockTarget.style.display = 'none';
    this.actuators_blockTarget.style.display = 'none';
    this.activities_blockTarget.style.display = 'none';
    this.blueprints_blockTarget.style.display = 'none';
    this.review_blocks_blockTarget.style.display = 'block';
  }

  connect() {
    var params = {}
    location.search.substr(1).split("&").forEach(function(item) {params[item.split("=")[0]] = item.split("=")[1]})
    // if (params['tab'] !== '') {
    //   this.[params['tab']]()
    // }
  }
}
