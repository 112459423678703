import { Controller } from "stimulus"
// import 'tinymce/skins/ui/oxide/skin.min.css'
// import 'tinymce/skins/content/default/content.css'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
// import 'tinymce/plugins/table'
// import 'tinymce/plugins/lists'
// import 'tinymce/plugins/image'

import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'


// import 'tinymce/themes/silver/theme.min.js'
// import tinymce from "tinymce/tinymce"
// import 'tinymce/themes/silver/theme.min.js'

// // Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';
//
// // A theme is also required
// import 'tinymce/themes/silver';
//
// // Any plugins you want to use has to be imported
// import 'tinymce/plugins/paste';
// import 'tinymce/plugins/link';


export default class extends Controller {
  connect() {
    tinymce.init({
      selector: '#mce-editor',
      skin: false,
      // content_css: false,
      height: 500,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste imagetools wordcount",
        "code"
      ],
      toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code",
      content_css: '//www.tiny.cloud/css/codepen.min.css'
    })
  }
}
