import { BaseModule } from "quill-image-resize/src/modules/BaseModule"

export default class AltImg extends BaseModule {
  onCreate = () => {
    this.addToolbarButton()
  }

  onUpdate = () => {
    this.overlay.style.height = "auto"
  }

  addToolbarButton() {
    const _this = this
    const button = $create("span")
    const text = $create("small")

    text.textContent = "ALT"
    text.classList.add("h-100", "flex", "justify-center", "items-center")
    button.appendChild(text)
    Object.assign(button.style, this.options.toolbarButtonStyles)
    button.style.borderLeftWidth = "0"
    button.addEventListener("click", () => {
      _this.PopupEditor()
    })
    this.overlay.lastElementChild.appendChild(button)
    this.overlay.click()
  }

  PopupEditor() {
    const _this = this
    const popupContainer = $create("div")
    const overlayContainer = $create("div")
    const textContainer = $create("div")
    const textArea = $create("textarea")
    const title = $create("h4")
    const buttonCancel = $create("button")
    const buttonOk = $create("button")
    const buttonGroup = $create("div")
    const cancelText = "Cancelar"
    const okText = "Guardar"

    $setAttr(overlayContainer,"class","ql-html-overlayContainer ql-html-overlayContainer-altImg")
    $setAttr(popupContainer, "class", "ql-html-popupContainer-altImg")
    $setAttr(textContainer, "class", "ql-html-textContainer")
    $setAttr(textArea, "class", "ql-html-textArea")
    $setAttr(title, "class", "mb2 mt0")
    $setAttr(buttonCancel, "class", "ql-html-buttonCancel")
    $setAttr(buttonGroup, "class", "ql-html-buttonGroup")
    buttonCancel.innerHTML = cancelText
    buttonOk.innerHTML = okText
    
    buttonGroup.appendChild(buttonCancel)
    buttonGroup.appendChild(buttonOk)
    textContainer.appendChild(title)
    textContainer.appendChild(textArea)
    textContainer.appendChild(buttonGroup)
    popupContainer.appendChild(textContainer)
    overlayContainer.appendChild(popupContainer)
    document.body.appendChild(overlayContainer)
    this.getImageName(this.img, title)
    textArea.textContent = _this.img.alt
    document.querySelector('.ql-editor').click()

    buttonCancel.onclick = function () {
      document.body.removeChild(overlayContainer)
      _this.img.click()
    }
    overlayContainer.onclick = buttonCancel.onclick
    popupContainer.onclick = function (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    buttonOk.onclick = function () {
      _this.img.setAttribute("alt", textArea.value)
      document.body.removeChild(overlayContainer)
      _this.img.click()
    }
  }
  
  getImageName(image, title) {
    let img = image.src.split('/')
    title.textContent = img[img.length-1]
  }
}

function $create(elName) {
  return document.createElement(elName)
}

function $setAttr(el, key, value) {
  return el.setAttribute(key, value)
}