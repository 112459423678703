import { Controller } from "stimulus"
import Choices from 'choices.js'
import Api from '../../api/base'

export default class extends Controller {
  static targets = [ 'selectize_section', 'search_field' ]

  connect() {
    const selectizeSection = new Choices(this.selectize_sectionTarget,
      { itemSelectText: '', noResultsText: 'No hay resultados' }
    );
  }

  clearFilter() {
    this.search_fieldTarget.value = ''
  }
}
