PagesController = (function(){
  var _init = function() {
    window.code_editor = CodeMirror.fromTextArea(
      document.getElementById("page_body"), {
        lineNumbers: true,
        mode: "htmlmixed"
      }
    );
    var editable = document.getElementById('pageEditor');
    editable.addEventListener('input', function(e) {
      window.code_editor.setValue(this.innerHTML);
    });
    window.code_editor.on('blur', function(e) {
      editable.innerHTML = e.getValue();
    });
  }

  return {
    init: _init
  };
})();
