import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'actuatorsList', 'activitiesList', 'checkboxRow', 'actuatorCheckbox',
    'checkboxTag', 'activitiesSaveButton'
  ]

  connect(){
    this.markChecks()
  }

  showActuators() {
    this.actuatorsListTarget.style.display = 'block';
    this.activitiesListTarget.style.display = 'none';
    this.activitiesSaveButtonTarget.style.display = 'none';
  }

  showActivities() {
    this.actuatorsListTarget.style.display = 'none';
    this.activitiesListTarget.style.display = 'block';
    this.activitiesSaveButtonTarget.style.display = 'block';
    this.markChecks()
  }

  markChecks() {
    let actuators_from_tag = this.checkboxTagTarget.value.split(',')
    for (const check of this.actuatorCheckboxTargets) {
      check.checked = false
      actuators_from_tag.forEach((actuator_id) => {
        if(actuator_id == check.value){
          check.checked = true
        };
      });
    }
  }
}
