import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = [
    'selectize_question_type',
    'suffix'
  ]

  manageQuestionType() {
    var question_type = document.querySelector('select[name="question[question_type]"]').value
    let show_suffix = this.selectize_question_typeTarget.value == 'decimal_blank' ? 'db' : 'dn'
    console.log(question_type)
    if (question_type == 'input_integer') {
      document.querySelector('[id="min_value"]').style.display = 'block';
      document.querySelector('[id="max_value"]').style.display = 'block';
    } else if (question_type == 'input_decimal') {
      document.querySelector('[id="min_value"]').style.display = 'block';
      document.querySelector('[id="max_value"]').style.display = 'block';
    } else if (question_type == 'multiple_choice') {
      document.querySelector('[id="min_value"]').style.display = 'none';
      document.querySelector('[id="max_value"]').style.display = 'none';
    } else if (question_type == 'value') {
      document.querySelector('[id="min_value"]').style.display = 'none';
      document.querySelector('[id="max_value"]').style.display = 'none';
    } else if (question_type == 'decimal_blank') {
      document.querySelector('[id="min_value"]').style.display = 'none';
      document.querySelector('[id="max_value"]').style.display = 'none';
    }

    this.suffixTarget.classList.remove('db', 'dn')
    this.suffixTarget.classList.add(show_suffix)
  }

  connect() {
    this.manageQuestionType()
    const question_typeChoices = new Choices(this.selectize_question_typeTarget,
      {itemSelectText: ''}
    );
  }
}
