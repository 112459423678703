import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['selectize_search_stage', 'selectize_search_batch']

  connect() {
    const searchStageChoices = new Choices(this.selectize_search_stageTarget,
      {itemSelectText: ''}
    );

    const searchBatchChoices = new Choices(this.selectize_search_batchTarget,
      {itemSelectText: ''}
    );
  }
}
