import { Controller } from "stimulus"
import FroalaEditor from 'froala-editor'

// Load a plugin.
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/save.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/code_view.min.js'

// import 'froala-editor/js/plugins/image.min.js'

export default class extends Controller {
  static targets = ['editor']
  connect() {    
    // Initialize editor.
    
    var froala = new FroalaEditor(this.editorTarget, {
      events: {
        'contentChanged': function () {
          // Do something here.
          // this is the editor instance.
          // console.log(this.data);
        }
      }})

    
  }
}
