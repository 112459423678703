import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import ImageResize from 'quill-image-resize'
import Quill from "quill"
import { htmlEditButton } from "utilities/quill/htmlEditButton"
import Choices from 'choices.js'
import Hr from "utilities/quill/hr"
import AltImg from "utilities/quill/altImg"
import axios  from 'axios'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import "quill-mention"

Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register("formats/hr", Hr);

const Parchment = Quill.import('parchment')
const hasTextAttributor = new Parchment.Attributor.Class(
  'btn-style', 'pub-btn',
  {
    scope: Parchment.Scope.INLINE, whitelist: [
      'primary', 'success', 'maxbutton'
    ]
  }
)
Quill.register(hasTextAttributor)

const videoAttributor = new Parchment.Attributor.Class(
  'video-res', 'vid-res', 'ql-video',
  { scope: Parchment.Scope.INLINE, whitelist: ['video-res', 'vid-res'] }
)
Quill.register(videoAttributor)

async function suggestConcepts(searchTerm) {
  try {
    const response = await axios.get('/api/v1/concepts/concepts_mention')
    return response.data.filter(
      concept => concept.value
                        .toLowerCase().normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, "")
                        .includes(searchTerm.toLowerCase())
    )
  } catch (error) {
    console.error(error)
  }
}

export default class extends Controller {
  static targets = [
    'editor', 'form', 'tag', 'picture', 'output', 'thumbnail', 'output2',
    'input'
  ]
  prepareParams(e) {
    if(this.editor.getLength() > 1) {
      this.inputTarget.value = this.editor.root.innerHTML
    }
  }
  connect() {
    const tagType = new Choices(this.tagTarget, {itemSelectText: ''})

    let visible = document.getElementById('publication_visible')

    visible.addEventListener("change", function(e) {
      let fp = flatpickr("#publication_published_at", {
        locale: Spanish,
        enableTime: true,
        time_24hr: true,
      })
      if (fp.element.value === "") {
        fp.setDate(new Date(), "Y-m-d H:i")
      }
    })

    this.inputTarget.form.addEventListener(
      'submit', this.prepareParams.bind(this)
    )

    this.editorTarget.innerHTML = this.inputTarget.value
    this.editor = new Quill(this.editorTarget,{
      theme: 'snow',
      modules: {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          showDenotationChar: false,
          mentionDenotationChars: ["#"],
          defaultMenuOrientation: 'top',
          source: async function(searchTerm, renderList) {
            const matchedPeople = await suggestConcepts(searchTerm);
            renderList(matchedPeople);
          }
        },
        htmlEditButton: { msg: '' },
        imageResize: {
          modules: [ 'Resize', 'DisplaySize', 'Toolbar', AltImg ]
        },
        toolbar: {
          container: '#toolbar-container',
          handlers: {
            'hr': Hr.handler,
            'image': this.imageHandler()
          }
        }
      }
    })
    this.editorTarget.editor = this.editor

    const error_message =`<div class="error red" id="error-message"></div>`

    var keywords = document.querySelector('.publication_keywords').lastElementChild
    var subsection = document.forms[0]["publication[subsection_id]"];
    var title = document.forms[0]["publication[title]"];
    var image_input = document.forms[0]["publication[image]"];
    title.insertAdjacentHTML('afterend', error_message);
    subsection.insertAdjacentHTML('afterend', error_message);
    keywords.insertAdjacentHTML('afterend', error_message);
    image_input.insertAdjacentHTML('afterend', error_message);
  }

  imageHandler() {
    let controller = this
    return function() {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.click()

      input.onchange = () => {
        const file = input.files[0]

        // Ensure only images are uploaded
        if (/^image\//.test(file.type)) {
          controller.uploadImage(controller.editor, file)
        } else {
          alert('Por favor selecciona una imagen')
        }
      }
    }
  }

  validateForm(event) {
    var is_new = document.querySelector(".edit_publication") == null
    var image_input = document.forms[0]["publication[image]"];
    var element = document.getElementById("error-message");
    var keywords = document.forms[0]["publication[keywords]"]
    var subsection = document.forms[0]["publication[subsection_id]"]
    var title = document.forms[0]["publication[title]"]
    var ext = image_input.value.split('.')[1]

    var div = document.getElementsByName("publication[image]")[0].parentNode
    if (subsection.value == "") {
      subsection.parentElement.querySelector('#error-message').innerText = 'Este campo es requerido'
      event.preventDefault();
    } else {
      subsection.parentElement.querySelector('#error-message').innerText = ''
    }
    if (keywords.value.split(',').length < 3 || keywords.value.split(',').length > 6) {
      var keywords_err = document.querySelector('.publication_keywords')
      keywords_err.querySelector('#error-message').innerText = 'Debe de haber Min. 3 y Max. 6 contenidos relacionados'
      event.preventDefault();
    } else {
      var keywords_err = document.querySelector('.publication_keywords')
      keywords_err.parentElement.querySelector('#error-message').innerText = ''
    }
    if (title.value == "") {
      title.parentElement.querySelector('#error-message').innerText = 'No puede estar en blanco'
      event.preventDefault();
    } else {
      title.parentElement.querySelector('#error-message').innerText = ''
    }
    if (image_input.value == "" && is_new) {
      image_input.parentElement.querySelector('#error-message').innerText = 'Debe de existir la imagen'
      event.preventDefault();
      return
    } else if (image_input.value != "" && ext != 'jpeg' && ext != 'png' && ext != 'jpg') {
      image_input.parentElement.querySelector('#error-message').innerText = 'La extensión debe de ser jpeg, jpg o png.'
      event.preventDefault();
    } else {
      image_input.parentElement.querySelector('#error-message').innerText = ''
    }

    if(!event.defaultPrevented) {
      document.getElementById("submit_button").innerHTML = "Cargando...";
    }
  }

  uploadImage(textEditor, file) {
    var fd = new FormData()
    fd.append('blob', file)

    var upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        this.insertImage(
          textEditor,
          `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
        )
      }
    })
  }

  insertImage(textEditor, fileUrl) {
    const range = textEditor.getSelection()
    textEditor.insertEmbed(range.index, 'image', fileUrl)
  }

  previewImage() {
    var input = this.pictureTarget
    var output = this.outputTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

  previewThumbnail() {
    var input = this.thumbnailTarget
    var output = this.output2Target

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
    }
  }
}
