import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['locationsCheck', 'locationsBlock', 'solutionsCheck', 'solutionsBlock']

  connect() {
    if(this.locationsCheckTarget.checked == true) {
      this.locationsBlockTarget.style.display = 'none'
    } else {
      this.locationsBlockTarget.style.display = 'block'
    }

    if (this.solutionsCheckTarget.checked == true) {
      this.solutionsBlockTarget.style.display = 'none'
    } else {
      this.solutionsBlockTarget.style.display = 'block'
    }
  }

  checkLocations(event) {
    if (event.target.checked == true) {
      this.locationsBlockTarget.style.display = 'none'
    } else {
      this.locationsBlockTarget.style.display = 'block'
    }
  }

  checkSolutions(event) {
    if (event.target.checked == true) {
      this.solutionsBlockTarget.style.display = 'none'
    } else {
      this.solutionsBlockTarget.style.display = 'block'
    }
  }
}