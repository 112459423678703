import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = [
    'actuate_on_me_button', 'actuators_button',
    'actuate_on_me_block', 'actuators_block'
  ]

  actuate_on_me() {
    this.actuate_on_me_buttonTarget.classList.add('bg-dart-red')
    this.actuators_buttonTarget.classList.remove('bg-dart-red')
    this.actuate_on_me_blockTarget.style.display = 'block';
    this.actuators_blockTarget.style.display = 'none';
  }

  actuators() {
    this.actuate_on_me_buttonTarget.classList.remove('bg-dart-red')
    this.actuators_buttonTarget.classList.add('bg-dart-red')
    this.actuate_on_me_blockTarget.style.display = 'none';
    this.actuators_blockTarget.style.display = 'block';
  }

  connect() {
    var params = {}
    location.search.substring(1).split("&").forEach(function(item) {params[item.split("=")[0]] = item.split("=")[1]})
  }
}
