import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['selectize_search_material']

  connect() {
    const materialChoices = new Choices(this.selectize_search_materialTarget,
      {itemSelectText: ''}
    );
  }
}
