import { Controller } from "stimulus"

export default class extends Controller {
  actionRequest(event) {
    event.preventDefault()
    var obj = event.target
    var parent = obj.parentNode
    var wrapper = parent.firstChild
    var url = ''
    if (obj.checked) {
      url = 'add_ability'
    } else {
      url = 'remove_ability'
    }
    var data = {};
    data['ability'] = obj.value;
    data['role_id'] = document.getElementById('abilities').dataset['role'];

    const token = document.getElementsByName("csrf-token")[0].content;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{ 'Content-Type': 'application/json', 'X-CSRF-Token': token }
    }).then(function (response) {
      return response.json();
    }).then(function(response) {
      obj.checked = response
      if(obj.checked){
        wrapper.classList.add('green')
        wrapper.classList.remove('red')
      } else {
        wrapper.classList.add('red')
        wrapper.classList.remove('green')
      }
    })
    .catch(function (error) {
      console.log(error);
      obj.checked = !responseValue
    })
  }
}
