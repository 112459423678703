import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['selectize_search_stage']

  connect() {
    const searchStageChoices = new Choices(this.selectize_search_stageTarget,
      {itemSelectText: ''}
    );
  }
}
