import { Controller } from "stimulus"
import Choices from 'choices.js'
import RemoteChoices from '../../utilities/choices_remote.js'

export default class extends Controller {
  static targets = ['selectize_search_type', 'selectize_material_family']

  connect() {
    const searchTypeChoices = new Choices(this.selectize_search_typeTarget,
      {itemSelectText: ''}
    );

    const searchMaterialFamilyChoices = new Choices(this.selectize_material_familyTarget,
      {itemSelectText: ''}
    );
  }
}
