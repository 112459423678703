import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    MicroModal.init();
    this.hideMenuOnOutsideClick()
    var dropdownMenus = document.getElementsByClassName('dropdown-container');
    this.iterateDropDownMenus(dropdownMenus, this.hideOpenMenus)
  }

  iterateDropDownMenus(elements, hideOpenMenus) {
    Array.from(elements).forEach(function(element) {
      element.addEventListener('click', function() {
        var menu = this.getElementsByClassName('dropdown-menu')[0]
        var openElement = document.querySelector('div.dropdown-menu.active')
        if(!menu.classList.contains('active')){
          hideOpenMenus(openElement)
        }
        menu.classList.toggle('dn')
        menu.classList.toggle('active')
      });
    });
  }

  hideOpenMenus(element){
    if(element !== null) {
      element.classList.add('dn')
      element.classList.remove('active')
    }
  }

  hideMenuOnOutsideClick(){
    window.addEventListener("click", function(event){
      var element = document.querySelector('div.dropdown-menu.active')
      if(element !== null){
        var elementMatcher =  element.closest('.dropdown-container').getElementsByClassName('display-toggler')[0]
        if(event.target !== elementMatcher && event.target.parentElement !== elementMatcher) {
          element.classList.add('dn')
          element.classList.remove('active')
        }
      };
    });
  }
}
