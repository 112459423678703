import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['selectize_search_solution_type']

  connect() {
    const searchSolutionTypeChoices = new Choices(this.selectize_search_solution_typeTarget,
      {itemSelectText: ''}
    );
  }
}
