import { Controller } from "stimulus"
import ImageResize from 'quill-image-resize';
import Quill from "quill"
import { htmlEditButton } from "utilities/quill/htmlEditButton";
import Hr from "utilities/quill/hr"
import Choices from 'choices.js'
import axios  from 'axios';

import "quill-mention"
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register("formats/hr", Hr);

async function suggestConcepts(searchTerm) {
  try {
    const response = await axios.get('/api/v1/concepts/concepts_mention');
    return response.data.filter(concept => concept.value
                                                  .toLowerCase()
                                                  .normalize('NFD')
                                                  .replace(/[\u0300-\u036f]/g, "")
                                                  .includes(searchTerm.toLowerCase()));
  } catch (error) {
    console.error(error);
  }
}

export default class extends Controller {
  static targets = ['editor', 'form', 'selectize_concept_type', 'img', 'img_name']
  prepareParams(e) {
    let content = document.querySelector('[name="concept[definition]"]')
    content.value = this.editorTarget.editor.root.innerHTML;
  }
  connect() {

    const selectizeConceptType = new Choices(this.selectize_concept_typeTarget,
      {itemSelectText: ''}
    );

    this.imgTarget.addEventListener(
      'change',
      function(event) {
        let img_name = event.srcElement.value.slice(12)
        let img_label = document.getElementsByClassName('img_name')[0]
        let label = document.createElement("p")
        let text = document.createTextNode(img_name)
        label.appendChild(text)
        img_label.appendChild(label)
      }
    );

    var content = document.querySelector('[name="concept[definition]"]')
    this.editorTarget.innerHTML = content.value
    let editor = new Quill(this.editorTarget, {
      theme: 'snow',
      modules: {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          showDenotationChar: false,
          mentionDenotationChars: ["#"],
          source: async function(searchTerm, renderList) {
            const matchedPeople = await suggestConcepts(searchTerm);
            renderList(matchedPeople);
          }
        },
        htmlEditButton: { msg: '' },
        imageResize: {
          displaySize: true
        },
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['blockquote', 'code-block'],
          ['link', 'image'],

          ['clean']
        ]
      }
    });
    this.editorTarget.editor = editor
  }
}
